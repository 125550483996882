<template>
	<div>
		<CCard class="p-4"> 
			<CCardHeader>
				<CRow>
			
					<CCol lg="6">
						<h5>Delivery Receipt /{{dataParams.booking.booking_no}}/ {{dataParams.dr_id}}</h5>
					</CCol> 
					<CCol lg="6">
			      		<div style="text-align: right"> 
			      			<CButton  size="sm" class="px-3" color="btn btn-info" @click="print"><i class="fa fa-print"></i> Print</CButton> &nbsp; 
			      			<CButton size="sm" class="px-3" color="btn btn-outline-danger" @click="backToTable()"><i class="fa fa-close"></i>Close</CButton> &nbsp; 
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
			<CCardBody>
		<fieldset class="fieldset full-line">
			<legend> DR Information</legend>
					<CRow>
						
						<CCol lg="4">
							Customer: <strong>{{dataParams.customer ? dataParams.customer.customer_name : "N/A"}}</strong>
						</CCol>
						<CCol lg="4">
							Delivery Date: <strong>{{dataParams.delivery_date ? moment(new Date (dataParams.delivery_date*1000)).format('MMMM DD, YYYY') : "N/A"}}</strong>
						</CCol>  
						<CCol lg="4">
							Department: <strong>{{dataParams.department ? dataParams.department : "N/A"}}</strong>
						</CCol>  
					</CRow>
					<CRow>
						<!-- <CCol lg="2"></CCol> -->
						<CCol lg="4">
							Address: <strong>{{dataParams.address ? dataParams.address : "N/A"}}</strong>
						</CCol> 
						<CCol lg="4">
							Contact Person: <strong>{{dataParams.contact_person ? dataParams.contact_person : "N/A"}}</strong>
						</CCol>
						 
					</CRow>
				
		</fieldset>

		<fieldset class="fieldset full-line">
			<legend> DR Items</legend>
					<CRow class="mb-1">
		        		<CCol lg="12">
		        			<CDataTable
						      	:items="dataParams.delivery_receipt_item"
						      	:fields="[
									 {
										key: 'asset_type_view', 
										label: 'Asset Type'
									},
									{
										key: 'no', 
										label: 'Asset No.'
									},
									{
										key: 'qty', 
										label: 'Qty'
									},
									{
										key: 'unit', 
										label: 'Unit'
									},
									{
										key: 'particular', 
										label: 'Particular'
									},
									{
										key: 'serial_no_view', 
										label: 'Serial No.'
									},
									{
										key: 'brand_view', 
										label: 'Brand'
									}
								]"
								hover
								striped
								border
								small
								fixed
							    
						    >   
						    </CDataTable> 
							<CCol lg="12">
							Remarks: <strong>{{dataParams.remarks ? dataParams.remarks : "N/A"}}</strong>
						</CCol>
		        		</CCol> 
		        	</CRow>    
		</fieldset>
		<fieldset class="fieldset full-line">
			<legend> References</legend>
					<CRow class="ml-3 mr-3 mt-2">
						<!-- <CCol lg="2"></CCol> -->
						<CCol lg="3">
							Released By: <strong>{{dataParams.released_by ? dataParams.released_by : "N/A"}}</strong>
						</CCol>
						<CCol lg="3">
							Checked By: <strong>{{dataParams.checked_by ? dataParams.checked_by : "N/A"}}</strong>
						</CCol> 
						
						<CCol lg="3">
							Received By: <strong>{{dataParams.received_by ? dataParams.received_by : "N/A"}}</strong>
						</CCol> 
						<CCol lg="3">
							Delivered By: <strong>{{dataParams.delivered_by ? dataParams.delivered.name : "N/A"}}</strong>
						</CCol>
					</CRow>
		</fieldset>
				
			</CCardBody> 
		</CCard> 
		<Print :dataParams="dataParams" />
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment'; 
import Print from './print';

export default {
	mounted(){  

		this.getData(); 

	},
	data(){
		return{
	
			formModal: false,
			showViewModal: false,
			showHistoryModal: false,
			dataList: [],
			dataListInactive: [],
			depotName: "",
			bookingParams:{
				booking_no:""
			},
			dataParams: {
				booking_no: "",
				delivery_date: "",
				address: "",
				contact_person: "",
				department: "",
				released_by: "",
				remarks: "",
				checked_by: "",
				received_by: "",
				delivered_by: "",
				dr_id: "",
				delivery_receipt_item: [
					{
						no: "",
						qty: "",
						unit: "units",
						particular: ""
					}
				],
				booking: {
					booking_no: ""
				}
			},
			editMode: false, 
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Print},
	methods: { 

		getData(){

		this.$showLoading(true)
			axios.get(config.api_path+"/deliveryReceipt/"+this.$route.params.dr_id)
			.then(response=>{  
				this.$showLoading(false)
				this.dataParams = response.data.data;  
				this.dataParams.delivery_receipt_item = this.dataParams.delivery_receipt_item.map((value, index)=>{
					value.asset_type_view = value.asset_type ;
					if(value.asset_type == "" || value.asset_type == null){
						value.asset_type_view = "N/A";
					}
					value.serial_no_view = value.serial_no ;
					if(value.serial_no == "" || value.serial_no == null){
						value.serial_no_view = "N/A";
					}
					value.brand_view = value.brand ;
					if(value.brand == "" || value.brand == null){
						value.brand_view = "N/A";
					}
					return value;
				})
			})

		},   

	    backToTable(){
	    	this.$router.push('/booking/'+this.$route.params.id+'/dr')
	    },

	    updateModal(item){
	    	this.$router.push('/main/update_booking/'+item.id)
	    },

	    viewDr(item){
	    	this.$router.push('/main/view_booking/'+item.id+"/dr")
	    },

		print(ev) {
			ev.preventDefault()
			var mywindow = window.open('', 'PRINT');

			mywindow.document.write('<html><head><title>' + document.title  + '</title>');
			mywindow.document.write('</head><body >');
			mywindow.document.write('<h1>' + document.title  + '</h1>');
			mywindow.document.write(document.getElementById('dr_print').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/
			
			setTimeout(() => {
				mywindow.print();
				mywindow.close();
			}, 500);
			

			return true;
		}

 	}
}
</script>
